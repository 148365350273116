// /var/www/website2024/dev.rampagesoft.com/my-app/src/component/NavigationBar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

import '../style/NavigationBar.scss'; // Import the SCSS file


const NavigationBar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto custom-nav">
            <Nav.Link as={Link} to="https://dev.rampagesoft.com/" className="nav-link text-index1">DEVELOP</Nav.Link>
            <Nav.Link as={Link} to="https://web-design.rampagesoft.com/" className="nav-link text-index1">WEBSITE DESIGN</Nav.Link>
            <Nav.Link as={Link} to="https://seo.rampagesoft.com/" className="nav-link">SEO</Nav.Link>
          </Nav>

          <Navbar.Brand as={Link} to="/" className="navbar-brand mx-auto">
            <img src="https://afaa.website/s/ff6970.png" alt="Logo"/>
          </Navbar.Brand>

          <Nav className="custom-nav">
            <NavDropdown title="SERVICES" id="collasible-nav-dropdown" className="nav-link">
              <NavDropdown.Item as={Link} to="https://web-design.rampagesoft.com/">WEBSITE</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://www.rampagesoft.com/mlm.php">MLM</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://www.rampagesoft.com/ecommerce.php">SHOP ONLINE</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://www.ttt-website.com/">WORDPRESS</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://www.rampagesoft.com/article.php">CONTENT</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://blog.ttt-website.com/">BLOG / NEWS</NavDropdown.Item>
            </NavDropdown>          
            
          </Nav>

          <Nav.Link as={Link} to="https://www.rampagesoft.com/AboutUs.php" className="nav-link">ABOUT</Nav.Link>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
