// /var/www/website2024/dev.rampagesoft.com/my-app/src/component/MetaTags.js

import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  title,
  description,
  author,
  charset = 'utf-8',
  viewport = 'width=device-width, initial-scale=1, maximum-scale=1',
  ogTitle,
  ogDescription,
  ogType = 'website',
  ogUrl,
  ogImage = 'https://afaa.website/img/resize50/15937b22-e2f8-41e5-807a-f0e222e2c928.webp',
  favicon = 'img/Logo/rampagesoft64.png',
  curatedKeywords
}) => (
  <Helmet>
    <title>{title}</title>
    <meta charset={charset} />
    <meta name="viewport" content={viewport} />
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="author" content={author} />
    <meta name="description" content={description} />
    <meta name="keywords" content={curatedKeywords.join(', ')} />
    <meta property="og:title" content={ogTitle || title} />
    <meta property="og:description" content={ogDescription || description} />
    <meta property="og:type" content={ogType} />
    <meta property="og:url" content={ogUrl} />
    <meta property="og:image" content={ogImage} />
    <link rel="icon" href={favicon} />
    {/* Additional meta tags can be added here */}
  </Helmet>
);

export default MetaTags;
