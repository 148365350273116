import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Toast } from 'primereact/toast';
import '../style/DataTableDisplay.scss';
import { classNames } from 'primereact/utils';

const fetchShoppingItemsMock = () => Promise.resolve([
  { id: 1, name: 'Item 1', price: 100, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 2, name: 'Item 2', price: 200, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 3, name: 'Item 3', price: 300, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 4, name: 'Item 4', price: 400, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 5, name: 'Item 5', price: 500, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 6, name: 'Item 6', price: 600, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 7, name: 'Item 7', price: 700, icon: 'https://afaa.website/s/ff6970.png' },
  { id: 8, name: 'Item 8', price: 800, icon: 'https://afaa.website/s/ff6970.png' },
]);

const ShoppingSection = () => {
  const [items, setItems] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    fetchShoppingItemsMock().then(data => setItems(data));
  }, []);

  const handleItemClick = (item) => {
    toast.current.show({ severity: 'info', summary: 'Item Price', detail: `${item.name}: ${item.price}฿` });
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // creates 4 columns
    gap: '5px', // spacing between grid items
  };

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    textAlign: 'center',
  };

  return (
    <div>
      <Toast ref={toast} />
      <div style={gridStyle}>
        {items.map((item, index) => (
          <div key={index} style={cardStyle}>
            <img src={item.icon} alt={item.name} className='product-image' />
            <h5>{item.name}</h5>
            <Button variant="primary" onClick={() => handleItemClick(item)}>
              <i className={classNames('pi', 'pi-shopping-cart')}></i> {`${item.price}฿`}
            </Button>{' '}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShoppingSection;
