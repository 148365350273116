// /var/www/website2024/dev.rampagesoft.com/my-app/src/component/SectionImage.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog'; // Ensure you have imported Dialog from PrimeReact

import '../style/SectionImage.scss';
// /var/www/website2024/dev.rampagesoft.com/my-app/src/style/SectionImage.scss

const SectionImage = ({ images }) => {
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const onClickImage = (image) => {
    setSelectedImage(image);
    setVisible(true);
  };

  const onHide = () => {
    setVisible(false);
  };

  // Prepare the header content, optionally using the image alt or src for SEO
  const dialogHeader = selectedImage ? `${selectedImage.alt}` : "Image Preview";

  return (
<>
    <h1 className='text-index2'> รับพัฒนาระบบ เพิ่มประสิทธิภาพการบริการ ระบบจองคิว จัดการบริหารงานขายแบบ GEN-Z  </h1>
    <div className="row">
     
      {images.map((column, index) => (
        <div key={index} className={`col-md-4 d-flex flex-column align-items-center`}>
          {column.map((image, idx) => (
            <img
              key={idx}
              src={image.imgSrc}
              alt={image.alt}
              className="img-fluid mb-2"
              onClick={() => onClickImage(image)}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </div>
      ))}


    
      <Dialog className='text-SectionImage' header={dialogHeader} visible={visible} onHide={onHide} modal>
        {selectedImage && (
          <img src={selectedImage.imgSrc} alt={selectedImage.alt} className="img-fluid" />
        )}
      </Dialog>
    </div>

    <div className="doubleline" ></div>
    </>
  );
};

SectionImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        imgSrc: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
};

export default SectionImage;
