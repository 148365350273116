///var/www/website2024/dev.rampagesoft.com/my-app/src/App.js

import React from 'react';
import './App.css';
import Home from './page/Home'; // Import Home component
import { Helmet } from 'react-helmet'; // Import Helmet

function App() {
  return (
    <div className="App">

      <Helmet>
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-9FQH17K31W"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9FQH17K31W');
          `}
        </script>
      </Helmet>

      <Home /> {/* Render Home component */}
    </div>
  );
}

export default App;
