import React from 'react';
import '../style/Footer.scss'; // Assuming you have a separate CSS file for footer styles

const Footer = () => {
  const layers = [...Array(24).keys()]; // Create 24 layers

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">

          <div className="footer-section">
            <p className='text-index5' >RAMPAGESOFT | <a href="https://afraapache.com/">AFRA APACHE</a> © 2017 – 2024</p>
            <ul className="footer-links">
              <li><a href="https://blog.ttt-website.com/">BLOG</a></li>
              <li><a href="https://afraapache.com/termsandconditions">TERMS AND CONDITIONS</a></li>
              <li><a href="https://afraapache.com/privacypolicy">PRIVACY POLICY</a></li>
            </ul>
          </div>

          <div className="footer-social-media text-index5">
            {/* Icons or links to social media would go here */}
            <a href="https://twitter.com/AFRAAPACHE"> X | </a>
            <a href="https://wa.me/message/WFPUI4FAAKGCK1"> WhatsApp | </a>
            <a href="https://line.me/R/ti/p/%40ave2890k"> LINE |</a>
            <a href="https://stackoverflow.com/users/3995751/siamwebsite"> Stackoverflow |</a>
            <a href="https://www.youtube.com/@rampagesoft"> YouTube |</a>
          </div>

        </div>
      </div>

      <div className="footer-gradient">
        {layers.map((layer) => (
          <div key={layer} className={`bg-primary-index  layer-${layer}`} style={{ marginTop: `${layer}px`, height: `${23 - layer}px` }}></div>
        ))}
      </div>

    </footer>
  );
};

export default Footer;
