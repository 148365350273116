// /var/www/website2024/dev.rampagesoft.com/my-app/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; // Make sure to import BrowserRouter
import './style/main.scss';

// At the top of your index.js or App.js file
import 'primereact/resources/themes/saga-blue/theme.css'; //theme is up to you
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> {/* Wrap your App in BrowserRouter */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
