import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'react-bootstrap';


const InputFormSection = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephone: '',
  });
  const toast = useRef(null);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to show error toast
  const showError = () => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'All fields must be filled', life: 3000 });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, telephone } = formData;

    // Check if all fields are filled
    if (name.trim() && email.trim() && telephone.trim()) {
      // Show a success toast with form data
      toast.current.show({
        severity: 'success',
        summary: 'Submitted',
        detail: `Name: ${name}, Email: ${email}, Telephone: ${telephone}`,
        life: 3000
      });
      console.log(formData);
      // Further processing...
    } else {
      // Show an error toast if any field is empty
      showError();
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <InputText name="name" value={formData.name} onChange={handleInputChange} placeholder="Name" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <InputText name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <InputText name="telephone" value={formData.telephone} onChange={handleInputChange} placeholder="Telephone" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button variant="primary" type="submit">Submit</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InputFormSection;
