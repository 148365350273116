import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { InputText } from 'primereact/inputtext';

import '../style/DataTableDisplay.scss';

const productServiceMock = {
  getProducts: () => Promise.resolve([
    { name: 'Product 1', image: 'ff6970.png', price: 100, category: 'Category 1', rating: 3 },
    { name: 'Product 2', image: 'ff6970.png', price: 200, category: 'Category 2', rating: 4.5 },
    { name: 'Product 3', image: 'ff6970.png', price: 300, category: 'Category 3', rating: 5 },
    // ... other products
  ])
};

const DataTableDisplaySection = () => {
  const [products, setProducts] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    productServiceMock.getProducts().then(data => setProducts(data));
  }, []);


  const imageBodyTemplate = (rowData) => {
    return <img src={`https://afaa.website/s/${rowData.image}`} alt={rowData.image} className="w-6rem shadow-2 border-round product-image" />;
  };


  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };

  const header = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
    </div>
  );

  return (
    <div>
      <div className="card">
        <DataTable value={products} header={header} globalFilter={globalFilter} emptyMessage="No products found">
          <Column field="name" header="Name" sortable></Column>
          <Column body={imageBodyTemplate} header="Image"></Column>
          <Column field="price" header="Price" sortable></Column>
          <Column field="category" header="Category" sortable></Column>
          <Column body={ratingBodyTemplate} header="Rating" sortable></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default DataTableDisplaySection;
