import React, { useEffect, useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';

const setNodeExpanded = (node) => {
  node.expanded = true;
  if (node.children) {
    node.children.forEach(setNodeExpanded);
  }
};

const fetchMLMDataMock = () => {
  const data = {
    "key": "root",
    "label": "CEO",
    "children": [
      {
        "key": "manager1",
        "label": "Manager 1",
        "children": [{ "key": "teamlead1", "label": "Team Lead 1" }]
      },
      {
        "key": "manager2",
        "label": "Manager 2",
        "children": [{ "key": "teamlead2", "label": "Team Lead 2" }]
      }
    ]
  };
  setNodeExpanded(data);
  return Promise.resolve(data);
};

const nodeTemplate = (node) => {
  return (
    <div>
      {node.label}
    </div>
  );
};

const MLMSection = () => {
  const [nodes, setNodes] = useState(null);

  useEffect(() => {
    fetchMLMDataMock().then(data => setNodes([data])); // Make sure it's an array containing the root node
  }, []);

  return (
    <div>
     
      {nodes && <OrganizationChart value={nodes} nodeTemplate={nodeTemplate} />}
    </div>
  );
};

export default MLMSection;
