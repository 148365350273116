import React from 'react';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import content from '../data/content.json';
import '../style/AgileDevelop.scss';

const contentKeys = ['Content12', 'Content13', 'Content14', 'Content15', 'Content16', 'Content17', 'Content18'];

const AgileDevelopContent = () => {
    const schemaOrgArticles = contentKeys.map(key => ({
        "@type": "Article",
        "name": content[key].title,
        "articleBody": content[key].description
    }));

    return (
        <div>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://dev.rampagesoft.com/agile-development"
                        },
                        "articleSection": schemaOrgArticles
                    })}
                </script>
            </Helmet>
            <h1 className='text-index2'>{content.Content19.title}</h1>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                mousewheel={{
                    enabled: true,
                    releaseOnEdges: true,
                    forceToAxis: true,
                }}
            >
                {contentKeys.map((key, index) => (
                    <SwiperSlide key={index}>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className='text-index5'>{content[key].title}</h3>
                                <p className="text-index5">
                                    {content[key].description}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default AgileDevelopContent;
