import React, { useState } from 'react';

const IncomeExpensesSection = () => {
  const [productTotal, setProductTotal] = useState(2500);
  const [income, setIncome] = useState(0);
  const [expenses, setExpenses] = useState(0);

  const handleProductTotalChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setProductTotal(parseInt(value, 10) || 0);
  };

  const handleIncomeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setIncome(parseInt(value, 10) || 0);
  };

  const handleExpensesChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setExpenses(parseInt(value, 10) || 0);
  };

  const resetAll = () => {
    setProductTotal(2500); // Reset to initial product total
    setIncome(0);
    setExpenses(0);
  };

  const getTotal = () => {
    return productTotal + income - expenses;
  };

  return (
    <div className="container">
      <div className="row align-items-end mb-3">
        <div className="col">
          <label htmlFor="productTotal">Product Total:</label>
          <input
            type="number"
            className="form-control"
            id="productTotal"
            value={productTotal}
            onChange={handleProductTotalChange}
          />
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <label htmlFor="income">Income:</label>
          <input
            type="number"
            className="form-control"
            id="income"
            value={income}
            onChange={handleIncomeChange}
          />
        </div>
        <div className="col">
          <button className="btn btn-success" onClick={() => setIncome(income + productTotal)}>
            <i className="pi pi-plus-circle"></i> Add Income
          </button>
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <label htmlFor="expenses">Expenses:</label>
          <input
            type="number"
            className="form-control"
            id="expenses"
            value={expenses}
            onChange={handleExpensesChange}
          />
        </div>
        <div className="col">
          <button className="btn btn-danger" onClick={() => setExpenses(expenses + productTotal)}>
            <i className="pi pi-minus-circle"></i> Add Expenses
          </button>
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <button className="btn btn-warning" onClick={resetAll}>
            <i className="pi pi-refresh"></i> Reset
          </button>
        </div>
        <div className="col">
          <label>Total Amount:</label>
          <input type="text" className="form-control" value={getTotal()} readOnly />
        </div>
      </div>
    </div>
  );
};

export default IncomeExpensesSection;
