// /var/www/website2024/dev.rampagesoft.com/my-app/src/component/BookingSection.js
import React, { useState, useRef } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';

import '../style/inputswitch.scss';

const BookingSection = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const toast = useRef(null);

  const handlePackageSelection = (packageName) => {
    setSelectedPackage(packageName);
    toast.current.show({
      severity: 'info',
      summary: 'Package Selected',
      detail: `You are selecting ${packageName}`,
      life: 3000
    });
  };

  return (
    <div className="booking-section">
      <Toast ref={toast} />
      <div className="package-switch">
        <label>
        Booking InputSwitch
          <InputSwitch checked={selectedPackage === 'Package 1'} onChange={(e) => handlePackageSelection('Package 1')} />
        </label>
      </div>
      <div className="package-switch">
        <label>
        แจ้งเตือนแบบ Toast
          <InputSwitch checked={selectedPackage === 'Package 2'} onChange={(e) => handlePackageSelection('Package 2')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        จัดการฟอร์มการจอง ติดต่อ สมัครสมาชิก ฯลฯ
          <InputSwitch checked={selectedPackage === 'Package 3'} onChange={(e) => handlePackageSelection('Package 3')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        แสดงรายการสินค้า คำสั่งซื้อ ข้อมูลลูกค้า ฯลฯ
          <InputSwitch checked={selectedPackage === 'Package 4'} onChange={(e) => handlePackageSelection('Package 4')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        Data Table จัดการแสดงข้อมูลอย่างมืออาชีพ
        <InputSwitch checked={selectedPackage === 'Package 5'} onChange={(e) => handlePackageSelection('Package 5')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        MLM Structure ขยายเครือข่ายขายตรง
          <InputSwitch checked={selectedPackage === 'Package 6'} onChange={(e) => handlePackageSelection('Package 6')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        Shopping ประสบการณ์การช้อปปิ้งที่ราบรื่น
          <InputSwitch checked={selectedPackage === 'Package 7'} onChange={(e) => handlePackageSelection('Package 7')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        Income & Expenses ติดตามการเงิน
          <InputSwitch checked={selectedPackage === 'Package 8'} onChange={(e) => handlePackageSelection('Package 8')} />
        </label>
      </div>

      <div className="package-switch">
        <label>
        ฟังก์ชั่นอื่นๆ อีกมากมาย
          <InputSwitch checked={selectedPackage === 'Package 9'} onChange={(e) => handlePackageSelection('Package 9')} />
        </label>
      </div>      

    </div>
  );
};

export default BookingSection;
