import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../style/DevelopmentQA.scss';

import content from '../data/content.json';
const contentKeys = ['Content20', 'Content21', 'Content22', 'Content23', 'Content24', 'Content25', 'Content26', 'Content27'];

const DevelopmentQASection = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const faqs = contentKeys.map(key => ({
        "@type": "Question",
        "name": content[key].title,
        "acceptedAnswer": {
            "@type": "Answer",
            "text": content[key].description,
        }
    }));

    return (
        <div className="qa-section">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": faqs
                    })}
                </script>
            </Helmet>
            <h2 className='text-index2'>{content.Content30.title}</h2>
            <Accordion className="custom-accordion" multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {contentKeys.map((key, index) => (
                    <AccordionTab header={`Q: ${content[key].title}`} key={index}>
                        <p>{`A: ${content[key].description}`}</p>
                    </AccordionTab>
                ))}
            </Accordion>
        </div>
    );
};

export default DevelopmentQASection;
