import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Mousewheel } from 'swiper/modules'; // Only import Autoplay from swiper modules
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/autoplay'; // Styles for the Autoplay module
import 'swiper/css/mousewheel'; // Import styles for Mousewheel

import '../style/SlideUp.scss'; // Your custom styles
import content from '../data/content.json';

const SlideUpComponent = () => {
  const contentKeys = ['Content8', 'Content9', 'Content10', 'Content11'];

  return (
    <Swiper
      modules={[Autoplay,Mousewheel]} // Specify Autoplay module directly in the Swiper component
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 5000, // Time delay between transitions in milliseconds
        disableOnInteraction: false, // Continue autoplay when the slider is interacted with
      }}
      mousewheel={{
        enabled: true, // Enable mousewheel control
        invert: false, // Normal direction of scrolling
        sensitivity: 1, // Mousewheel sensitivity
        releaseOnEdges: false, // Do not release mousewheel event on edges
        thresholdDelta: null, // Default value for scroll delta
        thresholdTime: null, // Default value for scroll time delta
      }}
    >
      {contentKeys.map((key, index) => (
        <SwiperSlide className='swiper-slide-index1' key={index}>
          <div itemScope itemType="http://schema.org/Article" className="content">

            <div className="text">
              <p itemProp="description" className='text-index5' >{content[key].description}</p>
            </div>

            <div className="image">
              {/* Using standard img tag without lazy loading for simplicity */}
              <img itemProp="image" src={content[key].Image} alt={content[key].title} className="img-fluid" />
            </div>

          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlideUpComponent;
