// /var/www/website2024/dev.rampagesoft.com/my-app/src/page/Home.js
import React from 'react';
import MetaTags from '../component/MetaTags.js';
import { Helmet } from 'react-helmet';

import NavigationBar from '../component/NavigationBar';
import SlideUpComponent from '../component/SlideUpComponent';
import TechnologyCard from '../component/TechnologyCard';

import SectionImage from '../component/SectionImage';
import BookingSection from '../component/BookingSection';
import InputFormSection from '../component/InputFormSection';
import DataTableDisplaySection from '../component/DataTableDisplaySection';
import MLMSection from '../component/MLMSection';
import ShoppingSection from '../component/ShoppingSection';
import IncomeExpensesSection from '../component/IncomeExpensesSection';
import AgileDevelopContent from '../component/AgileDevelopContent';
import DevelopmentQA from '../component/DevelopmentQA';
import Footer from '../component/Footer';

// Assuming content.json is stored in the ../data directory
import content from '../data/content.json';

const Home = () => {


  const imageGridData = [
    // First column with one image
    [
      { imgSrc: "https://afaa.website/img/resize50/072450cf-1857-4604-8d53-dcc3fdde0fee.webp", alt: "รับพัฒนาระบบ จองคิวรับบริการล่วงหน้าแบบปังๆ ไม่ต้องต่อคิววุ่นวาย BOOKING MANAGEMENT" },
      { imgSrc: "https://afaa.website/img/resize50/803156c2-c908-4012-889c-c267c0598a7f.webp", alt: "จัดการคิวรอคิวแบบมือโปร ไหลลื่น ไม่มีสะดุด ระบบจัดการ QUEUES MANAGEMENT" },
      { imgSrc: "https://afaa.website/img/resize50/0973a975-d46a-497a-a9db-1317986fb7a5.webp", alt: "รับพัฒนาระบบ เพิ่มประเภทบริการใหม่ๆ ตามใจ หรือจะ แก้ไขประเภทบริการแบบง่ายๆ TYPE SERVICES ️" },
    ],
    // Second column with two images
    [

      { imgSrc: "https://afaa.website/img/resize50/287066cb-aa37-4a33-baf7-72bb782b6e09.webp", alt: "รับพัฒนาระบบ รองรับการชำระเงินหลากหลายแบบ สะดวก ครบครัน PAYMENT MANAGEMENT " },
      { imgSrc: "https://afaa.website/img/resize50/8cd8b21f-59cd-4121-9b12-cc2aaa40f47a.webp", alt: "ระบบจัดการ ใบแจ้งหนี้ บันทึกข้อมูลใบแจ้งหนี้แบบออโต้ ไม่ต้องกังวล INVOICE MANAGEMENT" },

      { imgSrc: "https://afaa.website/img/resize50/e203d095-7831-4d46-89ad-423a7b04fb9f.webp", alt: "รับพัฒนาระบบ จัดการสินค้า หรือจะ ค้นหาบริการที่ต้องการแบบว่องไว SERVICES MANAGEMENT" },
    ],
    // Third column with one image
    [
      { imgSrc: "https://afaa.website/img/resize50/bd8ae24c-8fb4-4ed6-a97c-53215afebd44.webp", alt: "รับพัฒนาระบบ ติดตามสถานะงานแบบเรียลไทม์ รู้ทันใจ ORDERS MANAGEMENT" },
      { imgSrc: "https://afaa.website/img/resize50/3c67795a-db67-4e06-b263-e60d4615d96e.webp", alt: "ระบบจัดการ ออกใบเสร็จรับเงินแบบดิจิทัล เก็บไว้ชิลๆ RECEIPT MANAGEMENT" },

      { imgSrc: "https://afaa.website/img/resize50/444fc34b-8501-42cd-b937-3d52db016da4.webp", alt: "ระบบจัดการสินค้า แก้ไขข้อมูลบริการแบบครบครัน อัปโหลดรูปภาพบริการแบบปังๆ SERVICES MANAGEMENT" },
    ],
  ];


  const primaryKeywords = [
    'รับพัฒนาระบบ',
    'รับพัฒนาเว็บแอปพลิเคชัน',
    'บริษัทเขียนโปรแกรมที่ดีที่สุด',
    'ระบบบริหารจัดการองค์กร',
    'รับสร้างเว็บไซต์องค์กร',
    'การพัฒนาซอฟต์แวร์ตามความต้องการ',
  ];
  const secondaryKeywords = [
    'บริการเขียนแอพมือถือ',
    'ระบบ ERP สำหรับธุรกิจขนาดกลาง',
    'โซลูชันเว็บแอปพลิเคชัน',
    'ปรับแต่งซอฟต์แวร์สำเร็จรูป',
    'ระบบบริหารจัดการลูกค้า CRM',
  ];
  const relatedKeywords = [
    'การพัฒนาแอปพลิเคชันที่ปรับแต่งได้',
    'บริการโฮสติ้งแอปพลิเคชัน',
    'ระบบจัดการฐานข้อมูล',
    'การรวมระบบ IT',
    'การพัฒนาซอฟต์แวร์แบบ Agile',
  ];
  const technicalKeywords = [
    'Node.js การพัฒนาเว็บ',
    'React สำหรับแอปพลิเคชันด้านหน้า',
    'การพัฒนาแอปด้วย Flutter',
    'ระบบคลาวด์และการเก็บข้อมูล',
    'การปรับปรุงประสิทธิภาพเว็บไซต์ SEO',
  ];
  const curatedKeywords = [
    "POS System Development",
    "Retail POS Systems",
    "Queue Management System",
    "Spa Management System",
    "Cannabis Business Solutions",
    "Management system",
    "Shopping Cart",
    "School website",
    "Website Display",
    "MLM",
    "Stock inventory",
    "E-Commerce",
    "Booking",
    "Logistics",
    "PMS",
    "Resort",
    "Room Booking",
    "ERP",
    "CRM",
    "Restaurant",
    "Receipt",
    "Pledged",
    "E-Durable System",
    "ระบบพัฒนาระบบ POS",
    "ระบบหน้าร้าน",
    "ระบบจัดการคิว",
    "ระบบจัดการธุรกิจสปา",
    "ธุรกิจกัญชา",

  ];

  return (


    <div className='bg-index1'>

      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "รับพัฒนาระบบ ออกแบบระบบ ออกแบบเว็บไซต์ Web Applications Development | RAMPAGESOFT",
        "url": "https://dev.rampagesoft.com/",
        "description": "รับพัฒนาระบบ web development services"
      }
    `}
        </script>
      </Helmet>



      <MetaTags
        title="รับพัฒนาระบบ รับเขียนโปรแกรม Web Applications Development | RAMPAGESOFT"
        description="รับพัฒนาระบบ Development รับเขียนโปรแกรม รับทำระบบบริหารจัดการ รับพัฒนาเว็บแอปพลิเคชัน Spa Management System Restaurant พัฒนาระบบบริหารข้อมูลลูกค้า CMS จัดการสินค้า และการบริการ พัฒนาระบบตามความต้องของลูกค้า และกลุ่มเป้าหมาย | RAMPAGESOFT"
        author="RAMPAGESOFT"
        ogTitle="รับพัฒนาระบบ Development รับเขียนโปรแกรม รับพัฒนาเว็บแอปพลิเคชัน ออกแบบเว็บไซต์ | RAMPAGESOFT"
        ogDescription="รับพัฒนาระบบ รับเขียนโปรแกรม รับทำระบบบริหารจัดการ รับพัฒนาเว็บแอปพลิเคชัน ออกแบบเว็บไซต์ | RAMPAGESOFT"
        url="https://dev.rampagesoft.com/"
        ogUrl="https://dev.rampagesoft.com/"
        image="https://afaa.website/img/resize50/15937b22-e2f8-41e5-807a-f0e222e2c928.webp"
        ogimage="https://afaa.website/img/resize50/15937b22-e2f8-41e5-807a-f0e222e2c928.webp"
        primaryKeywords={primaryKeywords}
        secondaryKeywords={secondaryKeywords}
        relatedKeywords={relatedKeywords}
        technicalKeywords={technicalKeywords}
        curatedKeywords={curatedKeywords}
        canonicalUrl="https://dev.rampagesoft.com/"
      />

      <NavigationBar />

      <div className="container bg-index2">

        <div itemScope itemType="http://schema.org/Service">
          <div className="welcome-banner">
            <h1 itemProp="name" className='size-index1 text-index1'> {content.Content7.description} </h1>
            <h3 itemProp="description" className='text-right text-index2'>{content.Content6.description}</h3>
          </div>
        </div>

        <div itemScope itemType="http://schema.org/Service">
          <TechnologyCard />
        </div>


        {/* Dynamic content loaded from JSON */}
        <div className="row mt-4 mb-4">
          <div className="col-12">
            <h2 itemProp="name" className="section-title text-index2 doubleline">{content.Content1.title}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <p itemProp="description" className="highlight-text text-index3">{content.Content2.description}</p>

            <p itemProp="description" className='text-index4'>{content.Content5.description}</p>

          </div>

          <div className="col-md-8 text-index1 text-right">
            <p itemProp="description" className='text-index5'>{content.Content3.description}</p>
          </div>
        </div>

        <SlideUpComponent />


        <div className="row mt-5">
          <div className="col-md-12 text-index1 div-index1">
            <p itemProp="description" className='text-index5'>{content.Content4.description}</p>
          </div>
        </div>

        <SectionImage images={imageGridData} />

        <h1 itemProp="name" className='section-title text-index2'> ปลดล็อกศักยภาพ ด้วยฟังก์ชั่นที่ครบครัน จะช่วยให้คุณจัดการธุรกิจได้อย่างมีประสิทธิภาพ  </h1>

        <div className="row">
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card text-bg-primary mb-3 w-100">
              <div className="card-header">
                <h3 itemProp="name">Booking</h3>
              </div>
              <div itemProp="description" className="card-body">
                <BookingSection />
              </div>
            </div>
          </div>


          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card text-bg-secondary mb-3 w-100">
              <div className="card-header">
                <h3 itemProp="name">Input Form</h3>
              </div>
              <div itemProp="description" className="card-body">
                <InputFormSection />
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch">

            <div className="card text-bg-success mb-3 w-100">
              <div className="card-header">
                <h3 itemProp="name">Data Table Display</h3>
              </div>
              <div itemProp="description" className="card-body">
                <DataTableDisplaySection />
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-4 d-flex align-items-stretch">

            <div className="card text-bg-danger mb-3 w-100">
              <div className="card-header">  <h3>MLM Structure</h3> </div>
              <div className="card-body">
                <MLMSection />
              </div>
            </div>

          </div>

          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card text-bg-warning mb-3 w-100">
              <div className="card-header"><h3>Shopping</h3> </div>
              <div className="card-body">
                <ShoppingSection />
              </div>
            </div>
          </div>


          <div className="col-md-4 d-flex align-items-stretch">
            <div className="card text-bg-info mb-3 w-100">
              <div className="card-header">  <h3>Income & Expenses</h3> </div>
              <div className="card-body">
                <IncomeExpensesSection />
              </div>
            </div>
          </div>

        </div>

        <AgileDevelopContent />
        <DevelopmentQA />


      </div>
      <Footer />
    </div>
  );
};

export default Home;
