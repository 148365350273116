// TechnologyCard.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '../style/TechnologyCard.scss';


const TechnologyCard = () => {
  // Define the technologies array directly inside the component
  const technologies = [
    { title: "REACT", imgSrc: "https://afaa.website/s/1914ff.svg" },
    { title: "GO LANG", imgSrc: "https://afaa.website/s/65ec35.svg" },
    { title: "PYTHON", imgSrc: "https://afaa.website/s/012238.svg" },
    { title: "PHP", imgSrc: "https://afaa.website/s/ecf56a.svg" },
    { title: "SCSS", imgSrc: "https://afaa.website/s/358b5e.svg" },
    { title: "AI", imgSrc: "https://afaa.website/s/33ca61.png" },
     { title: "API", imgSrc: "https://afaa.website/s/15b8bd.svg" },
    { title: "CLOUD SERVER", imgSrc: "https://afaa.website/s/2ac52b.svg" },
    { title: "Database", imgSrc: "https://afaa.website/s/577ff1.svg" }, 
    { title: "PAYMENT GATEWAY", imgSrc: "https://afaa.website/s/ae656c.svg" },
   
  ];

  return (
    <Swiper
    spaceBetween={30}
    slidesPerView={4} // Default for larger screens
    modules={[Autoplay, Pagination, Navigation]}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{ clickable: true }}
    navigation={true}
    breakpoints={{
      // Screens smaller than 768px will show 2 slides per view
      320: {
        slidesPerView: 2,
      },
      // Screens larger than 768px will show 4 slides per view
      768: {
        slidesPerView: 4,
      },
    }}
  >
    {technologies.map((tech, index) => (
      <SwiperSlide key={index}>
        <div className="technology-card" itemScope itemType="http://schema.org/SoftwareApplication">
          <img src={tech.imgSrc} alt={tech.title} itemProp="image"/>
          <h5 itemProp="name">{tech.title}</h5>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
  );
};

export default TechnologyCard;
